#cancel-license-modal-container{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
}


#cancellation-license-image{
    height: 100px;
    width: auto;
}

#certain-cancellation-header{
    text-align: center;
    margin-top: 0;
}

#license-cancellation-disclaimer {
    font-size: 12px;
    font-style: italic;
}

#keep-license-button {
    height: 35px;
    margin-top: 10px;
    background-color: #128fdc;
    color: white;
    border: none;
    border-radius: 5px;
    width: 200px;
}

#keep-license-button:hover {
    cursor: pointer;
    background-color: #2c9ade;
}

#confirm-cancellation-button{
    height: 35px;
    margin-top: 20px;
    background-color: #97999a;
    color: white;
    border: none;
    border-radius: 5px;
    width: 200px;
}

#confirm-cancellation-button:hover {
    cursor: pointer;
    background-color: #737475;
}
