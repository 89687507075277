#signup-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background-image: url('https://exposure-flickr-clone.s3.us-west-2.amazonaws.com/IMG_20200815_131743727.jpg');
    background-size: cover;
}

#signup-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    /* justify-content: center; */
    height: 100vh;
}

#signup-page-form-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 300px;
    height: auto;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    align-items: center;
    box-shadow: 2px 2px 5px rgb(92, 92, 92);
    margin-top: 100px;
}

#signup-page-form {
    display: flex;
    flex-direction: column;
    align-items: left;
    /* justify-content: center; */
    width: 100%;
}

#signup-page-form h1 {
    text-align: center;
    font-size: 20px;
    padding: 0;
    color: rgb(34, 34, 34);
}

#signup-page-form i {
    font-size: 30px;
    color: #555555;
    align-self: center
}

#signup-page-background {
    position: absolute;
    width: 100%;
    min-height: 100%;
    overflow: auto;
    z-index: -1;
}

#signup-page-form label {
    margin-bottom: 5px;
    width: 100%;
}

#signup-page-form input {
    height: 2.25rem;
    width: 100%;
    font-size: .9rem;
    border-radius: 5px;
    border: 1px solid #555555;
}

#signup-button {
    width: 100%;
    margin-top: 20px;
    height: 2.5rem;
    background-color: #128fdc;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 15px;
}

#signup-button:hover {
    cursor: pointer;
    background-color: #2c9ade;
}

#form-bottom-text {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    /* gap: 3px; */
    margin-top: 5px;
    align-items: center;
}

#form-bottom-text p {
    margin-top: 10px;
    margin-bottom: 0;
}

#reroute-to-login-link {
    color: #128fdc;
}

#reroute-to-login-link:hover {
    text-decoration: underline;
}

#signup-form-photo-credit {
    align-self: flex-end;
    padding: 10px;
    margin: 10px;
    color: black;
    background-color: white;
    border-radius: 5px;
    opacity: 70%;
}


.signup-errors {
    font-size: 12px;
    height: 10px;
    margin-bottom: 5px;
    color: red;
}
