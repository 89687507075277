
#single-license-info-bar {
    display: grid;
    grid-template-columns: 1.5fr 2fr 1.5fr;
    width: 100%;

}

#single-license-page-container{
    display: flex;
    flex-direction: column;
    align-items: center;

}

#single-image-bar{
    width: 100%;
}

#single-license-artist-information{
    text-align: center;
    margin-top: 16px;
}

#single-license-information {
    display: flex;
}


#single-license-caption span{
    color: rgb(81, 79, 79);
    font-style: italic;
}

#single-license-title span{
    color: rgb(81, 79, 79);
    font-style: italic;
    margin-right: 10px;
}

#single-license-title{
    display: flex;
    margin-right: 20px;
}

#single-license-caption {
    width: 100px;
}

#single-license-description-container {
    display: flex;
}

#license-caption-body {
    width: 200px;
    margin-right: 20px;
}

#license-type-container{
    display: flex;
    gap: 10px;
}

#license-type-header{
    font-style: italic;
    color: rgb(81, 79, 79);
}
