#single-image-page-container {
    height: 100vh;
    background-color: #F3F5F6;
}

#single-image-bar {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(34, 34, 34);
}

#single-page-image {
    /* margin-top: 50px; */
    width: auto;
    max-width: 90%;
    max-height: 350px;
    min-height: 350px;
    height: 350px;
    /* padding: 30px; */
    /* max-height: 475px; */
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 2px rgb(119, 118, 118);
}

#single-image-info-bar {
    display: grid;
    /* justify-content: space-between; */
    grid-template-columns: 1fr 1.5fr 1fr;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #F3F5F6;
}

#single-image-cart-form-container{
    display: flex;
    flex-direction: column;
    /* justify-content: left; */
    align-items: left;
    /* align-self: center; */
}

#single-image-pricing-info{
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 10px;
}

#single-image-pricing-info p{
    margin: 0;
}

#single-image-add-cart-form {
    display: flex;
    gap: 10px;
    margin-left: 10px;
    margin-top: 10px;
}

#single-image-add-cart-form select {
    height: 30px;
    border-radius: 5px;
    border: 1px solid lightgray;
    width: 80px;
}

#single-image-add-cart-form button {
    height: 30px;
    background-color: #128fdc;
    color: white;
    border-radius: 5px;
    border: none;
    width: 100px;
}

#single-image-add-cart-form select:hover {
    cursor: pointer;
}

#single-image-add-cart-form button:hover {
    cursor: pointer;
    background-color: #2c9ade;
}

#single-image-artist-information{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
}

#single-image-artist-information i {
    align-self: center;
}

#single-image-artist-info{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

#single-image-edit-image-container {
    display: flex;
    gap: 5px;
    align-items: center;
}

.single-image-standard-text {
    font-style: italic;
    font-size: smaller;
    color: rgb(95, 93, 93);
    padding: 10px;
    margin-bottom: 10px;
}


#single-image-pricing-header {
    margin-bottom: 10px;
    font-style: normal;
}
#single-image-pricing-header span {
    font-style: normal;
}

#single-image-artist-info {
    text-align: center;
}

#single-image-uploaded-by {
    margin: 0;
    padding-bottom: 2px;
}

#single-image-edit-image-text {
    margin: 0;
    font-size: small;
    color: rgb(95, 93, 93);
    font-style: italic;
    display: flex;
    gap: 5px;
}

#single-image-edit-image-text i {
    color: rgb(95, 93, 93);
}
#single-image-edit-image-text i:hover {
    text-decoration: none;
}

#single-image-artist-name {
    margin: 0;
    margin-bottom: 8px;
}

#edit-image-button {
    background-color: transparent;
    border: none;
}

#edit-image-button:hover {
    cursor:pointer;
    color: rgb(95, 93, 93);
    text-decoration: underline;
}

#single-image-caption{
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    height: auto;
}

#single-image-description{
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
    word-wrap: normal;
}

#single-image-caption-span{
    margin-bottom: 0;
}

#comments-section-container {
    width: 100%;
    /* max-width: 445px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


#comments-box-wrapper{
    margin-top: 16px;
    width: 90%;
    max-width: 445px;
    background-color: lightgray;
    border: 1px solid lightgrey;
    border-radius: 5px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    padding: 10px;
}

#comments-container{
    width: 100%;
    max-height: 100%;
    min-height: 80%;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    padding-bottom: 10px;
    border-radius: 5px;
}

#comments-container::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(191, 190, 190);
    border-radius: 10px;
}

#comments-container::-webkit-scrollbar-thumb {
    background-color: #128fdc;
    border-radius: 10px;
}

.comment-content-container{
    display: flex;
    flex-direction: column;
}

.comment-info {
    display: flex;
    gap: 5px;
}

.comment-text-container{
    display: flex;
    align-items: center;
    gap: 5px;
}

.comment-user-name {
    font-size: smaller;
    margin: 0;
    color: #016DAC;
    margin-right: 5px;
}

.comment-date {
    font-size: 10px;
    display: flex;
    align-items: center;
    color: gray;
    margin: 0;
}

.comment-text-container {
    margin-left: 5px;
    /* max-width: 100%; */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.comment-text {
    font-size: smaller;
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 90%;
    /* overflow: scroll; */
    overflow-wrap: break-word;
}

#comment-message-bar{
    width: 345px;
    max-width: 345px;
    background-color: rgb(237, 237, 237);
    margin-bottom: 10px;
    height: 30px;
    padding: 5px;
    padding-left: 8px;
    border-radius: 5px;
    font-size: small;
    display: flex;
    align-items: center;
}

#single-image-artist-link {
    font-size: 15px;
    /* width: 50%; */
    color: #128fdc;
    text-align: center;
}

#single-image-artist-link:hover {
    text-decoration: underline;
}

#single-image-artist-link-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 60%;
}


#image-in-cart-error {
    font-size: smaller;
    padding-left: 10px;
    color: red;
}

#single-page-image-container {
    margin-top: 50px;
    width: auto;
    max-width: 90%;
    height: 400px;
    padding: 30px;
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

}

#comment-submission-form{
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    box-sizing: border-box;
}

#comment-message-bar{
    width: 100%;
    margin: 0;
    height: 100%;
    /* margin-right: 5px; */
    box-sizing: border-box;
}

#comment-message-bar textarea{
    width: 100%;
    min-width: 100%;
    /* max-width: 100%; */
    margin: 0;
    border: 1px solid lightgray;
    height: 100%;
    max-height: 50px;
    min-height: 50px;
    border-radius: 5px;
}

#comment-message-bar textarea:focus {
    outline: 1px solid #128fdc;

}

.comment-text-container i {
    font-size: 13px;
    margin-left: 10px;
    color: rgb(101, 100, 100);
}

#create-comment-button{
    height: 100%;
    width: auto;
    min-width: 45px;
    background-color: #128fdc;
    border: none;
    border-radius: 5px;
}

#create-comment-button:hover {
    background-color: #49a4dd;
    cursor: pointer;
}

.fa-paper-plane{
    color: white;
    font-size: larger;
}

.fa-trash-can:hover {
    cursor: pointer;
}
