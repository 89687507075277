.upload-image-page-container {
    display: flex;
    flex-direction: column;
    /* margin-top: 50px; */
    height: 100vh;
    min-height: fit-content;
    /* min-height: 100%; */
    /* justify-content: center; */
    align-items: center;
    background-color: #F2F5F6;
}

#upload-image-form-container{
    width: 400px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: fit-content;
    margin-top: 100px;


}

#upload-image-form{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    /* border: #555555 1px solid;
     */
    box-shadow: 1px 1px 3px rgb(92, 92, 92);
    /* justify-content: center; */
}

#upload-form-image-pricing-container{
    display: flex;
    flex-direction: column;
}

#upload-image-form label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-size: 15px;
    /* margin-bottom: 10px; */
}

.upload-image-input {
    border-radius: 5px;
    border: 1px solid #555555;
    margin-top: 5px;
    height: 25px;
}

#image-file{
    align-self: center;
    text-align: center;

}

#image-file-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    color: gray;
}

#image-file-input:hover {
    cursor: pointer;
}

#file-upload-button {
    color: white;
}



#upload-image-form textarea {
    min-height: 50px;
    max-height: 130px;
    min-width: 100%;
    max-width: 100%;
}

#upload-image-submit-button {
    margin-top: 10px;
    height: 40px;
    color: white;
    background-color: #128fdc;
    border-radius: 5px;
    border: none;
    font-size: 16px;
}

#upload-image-submit-button:hover {
    cursor: pointer;
    background-color: #2c9ade;
}

#license-price-input {
    width: 50px;
}

#image-file-header{
    margin-bottom: 10px;
    font-size: 20px;
}

.license-price-label-content {
    margin: 0;
}

.license-price-label-content i {
    color: #2c9ade;
    margin-left: 2px;
}

.upload-image-errors{
    color: red;
    font-size: 12px;
    margin: 2px;
}
