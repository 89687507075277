/* TODO Add site wide styles */

a {
    text-decoration: none;
}

* {
    font-family: 'Roboto', sans-serif;
    margin: none;
    padding: none;
    box-sizing: border-box;
    color: rgb(34, 34, 34);
}

#root {
    height: 100%;
    /* width: 100vw; */
}

body {
    padding: 0;
    margin: 0;

}

/* html {
    min-height: 100vh;
} */
