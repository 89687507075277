#home-page-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    /* width: 100vw; */
    height: 100%;
    min-height: 100vh;
    align-items: center;
    background-color: #F3F5F6;
}

#home-page-container{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#home-page-header {
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 200px;
    align-items: center;
    justify-content: center;
}

#home-page-image-display {
    width: 95%;
    background-color: rgba(255, 255, 255, 0.649);
    padding: 20px;
    border-radius: 5px;
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    /* margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;  */
}

#artist-pages-header{
    font-size: 22px;
    margin-top: 0;
    color: rgb(81, 80, 80);
}

#home-page-artist-grid-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

}

#home-page-grid-item-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid gray; */
    height: 200px;
    padding: 5px;
    width: 100%;
    background-color: #373737;
    border-radius: 5px;
}

.home-page-grid-item-image{
    max-width: 100%;
    height: 120px;
    /* object-fit: contain; */
    /* display: flex; */
}

.home-page-grid-item-text-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

}

.home-page-grid-item-artist-info{
    display: flex;
    gap: 5px;
    margin-bottom: 0;

}

#see-more-link-text {
    text-align: center;
    margin-bottom: 0;
}

.home-page-grid-item-fname  {
    margin-bottom: 0;
}

.home-page-grid-item-lname{
    margin-bottom: 0;
}

.home-page-grid-image {
    /* height: 150px; */
    width: 150px;
    transition: transform .2s;
}

.home-page-grid-image:hover {
    transform: scale(1.015)
}
