#login-page-wrapper {
    width: 100%;
    height: 100vh;
    background-image: url('https://exposure-flickr-clone.s3.us-west-2.amazonaws.com/IMG_20200712_130149473_HDR.jpg');
    background-size: cover;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

#login-form-wrapper{
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    width: 300px;
    min-width: 300px;
    height: 450px;
    border-radius: 5px;
    align-items: center;
    padding: 20px;
    margin-top: 200px;
    box-shadow: 2px 2px 5px rgb(92, 92, 92);
}

#login-form-wrapper h1 {
    font-size: 20px;
    color: rgb(34, 34, 34);
}

#login-form-wrapper i {
    font-size: 30px;
    color: #555555;
}

#login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#login-form ul {
    margin: 0;
    padding: 0;
    text-align: center;
}

#login-form label {
    width: 100%;
}

#login-form input {
    height: 2.25rem;
    width: 100%;
    font-size: .9rem;
    border-radius: 5px;
    border: 1px solid #555555;
    margin-bottom: 20px;
}

#login-page-login-button {
    width: 100%;
    margin-bottom: 10px;
    height: 2.5rem;
    background-color: #128fdc;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 15px;
}

#login-page-login-button:hover {

    background-color: #3196d5;
    cursor: pointer;
}

#login-form-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#login-form-text-wrapper p {
    margin-bottom: 10px;
    margin-top: 0;
}

#signup-reroute {
    color: #128fdc;
    margin-bottom: 0;
}

#signup-reroute:hover {
    text-decoration: underline;
    cursor: pointer;
}

#login-error-message {
    color: red;
    height: 30px;
    margin: 0;
    padding: 0;
    font-style: italic;
}

#login-page-login-demo-button {
    width: 100%;
    margin-bottom: 20px;
    height: 2.5rem;
    background-color: #128fdc;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 15px;
}

#login-page-login-demo-button:hover {
    background-color: #3196d5;
    cursor: pointer;
}
