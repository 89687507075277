.hidden {
  display: none;
}

#navbar-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #555555;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 2px solid #0c0c0c31;
  position: fixed;
  top: 0;
  z-index: 1;
}

#header-logo-and-title {
  display: flex;
  align-items: center;
  margin-left: 0px;
  gap: 10px;
}

#header-logo-and-title > p {
  font-size: 25px;
  font-style: italic;
  color: white;
}

#header-logo-and-title i {
  color: white;
}


#navbar-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile-dropdown{
  position: absolute;
  top: 50px;
  right: 20px;
  background-color: white;

  border: 1px solid #555555;
  border-radius: 5px;
  width: 200px;
  height: 200px;
  list-style: none;
  padding: 20px;
}

.profile-dropdown p:hover {
  text-decoration: underline;
  color: rgb(123, 121, 121);
}

#profile-button {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border: none;
}

#profile-button:hover {
  cursor: pointer;
}

#upload-cloud-icon {
  color: white;
  font-size: 25px;
}

#upload-cloud-icon:hover {
  cursor: pointer;
  color: #cae9fa;

}

#shopping-cart-dropdown {
  position: absolute;
  top: 66px;
  right: 20px;
  background-color: white;
  border: 1px solid #555555;
  border-radius: 5px;
  width: 300px;
  /* min-height: 80vh; */
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
}

.shopping-cart-dropdown-image{
  height: 50px;
  width: auto;
  max-height: 50px;
}

#cart-dropdown-header {
  display: flex;
  height: 30px;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(202, 200, 200);
  width: 40%;
}

.cart-dropdown-item-container {
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid rgb(202, 200, 200);
}

#shopping-cart-dropdown ul {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0;
}

.cart-dropdown-info p {
  font-size: 11px;
  margin: 0;
  margin-left: 10px;
}

.cart-dropdown-trash {
  background-color: transparent;
  border: none;
}

.cart-dropdown-trash i {
  color: #555555;
}

.cart-dropdown-trash i:hover {
  color: #f02f2f;
  cursor: pointer;
}

.cart-dropdown-content {
  display: flex;
  align-items: center;
}

#cart-dropdown-checkout-button {
  color: white;
  background-color: #128fdc;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 60%;
  height: 30px;
  border: none;
  border-radius: 5px;
}

#cart-dropdown-checkout-button:hover {
  cursor: pointer;
  background-color: #419cd5;
}

#cart-button {
  height: 25px;
  display: flex;
  background-color: transparent;
  border: none;
  justify-content: center;
}

#cart-icon-button {
  color: white;
  height: 25px;
  width: 25px;
  font-size: 20px;
  display: flex;
  align-items: center;
}

#cart-icon-button:hover {
  cursor: pointer;
  color: #cae9fa;
}

#items-in-cart-number{
  background-color: #128fdc;
  color: white;
  z-index: 10;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 12px;
  margin: 0;
  top: 8px;
  right: 58px;
}

#navbar-buttons-no-user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-button-no-user{
  width: 80px;
  height: 25px;
}

#login-navbar-button {
  background-color: transparent;
  color: white;
  border: none;
  width: 50px;
}

#login-navbar-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

#signup-navbar-button {
  background-color: white;
  border: none;
  border-radius: 5px;
}

#signup-navbar-button:hover {
  color: rgb(116, 114, 114);
  cursor: pointer;
}

#home-link-wrapper {
  display: flex;
  align-items: center;
  /* justify-content: center; */

}

#navbar-home-link{
  color: white;
  margin-top: 5px;
  font-size: 15px;
}

#navbar-home-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

#navbar-left-container {
  display: flex;
  gap: 40px;
}

#profile-button-logout-button {
  background: transparent;
  border: 1px solid gray;
  width: 80px;
  border-radius: 5px;
}

#profile-button-logout-button:hover {
  background-color: rgb(237, 233, 233);
  cursor: pointer;
}
